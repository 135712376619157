import colors from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";
import { hexToRgba } from "../utils";

const MuiLinkTheme: MuiElementTheme<"MuiLink"> = {
  styleOverrides: {
    root: {
      color: colors.teal[40],
      fontSize: "0.875rem",
      textUnderlineOffset: "3px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
      padding: "2px",
      "&:hover": {
        color: colors.teal[30]
      },
      "&:active": {
        boxShadow: `0 0 0 4px ${hexToRgba(colors.teal[50], 0.5)}`
      }
    }
  }
};

export default MuiLinkTheme;
