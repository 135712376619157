import {
  getCountryCodeForRegionCode,
  parsePhoneNumber
} from "awesome-phonenumber";

/*
 * Returns a displayable phone number
 * (for now, only show a "friendly" number when it is a US number
 *  since we don't have a great way of deducing which country code is local)
 */

export const displayPhoneNumber = (
  phoneNumber?: string | null
): string | undefined => {
  const pn = parsePhoneNumber(phoneNumber ?? "");
  if (pn.valid) {
    return getCountryCodeForRegionCode(pn.regionCode) === 1
      ? pn.number.national
      : pn.number.international;
  }
  return phoneNumber ?? undefined;
};
