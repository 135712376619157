import colors from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";
import { hexToRgba } from "../utils";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    LinkCard: true;
  }
}

const ButtonLinkTheme: MuiElementTheme<"MuiButton"> = {
  variants: [
    {
      props: { variant: "LinkCard" },
      style: {
        backgroundColor: colors.teal[80],
        color: colors.teal[30],
        "&:hover": {
          backgroundColor: colors.teal[70],
          color: colors.teal[20]
        },
        "&:active": {
          backgroundColor: colors.teal[70],
          color: colors.teal[50]
        },
        "&.Mui-disabled": {
          backgroundColor: hexToRgba(colors.teal[80], 0.5),
          color: hexToRgba(colors.teal[30], 0.5)
        },
        "& .MuiButton-endIcon": {
          display: "flex",
          flexGrow: 1,
          justifyContent: "flex-end"
        }
      }
    }
  ]
};

export default ButtonLinkTheme;
