import { getColor } from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    teal: true;
    gold: true;
    coral: true;
    gray: true;
    red: true;
    yellow: true;
    green: true;
    blue: true;
    grape: true;
    guava: true;
    tangerine: true;
    lemon: true;
    lime: true;
    ocean: true;
    berry: true;
    plum: true;
    coconut: true;
    carrot: true;
  }
}

const MuiChipTheme: MuiElementTheme<"MuiChip"> = {
  defaultProps: {
    size: "small",
    color: "gray"
  },
  styleOverrides: {
    root: ({ ownerState }: any) => ({
      alignItems: "center",
      width: "fit-content",
      gap: "0.25rem",
      height: "auto",
      maxWidth: "35vw",
      backgroundColor: getColor(ownerState.color, 80),
      "&.MuiChip-clickable": {
        "&:hover": {
          backgroundColor: getColor(ownerState.color, 70)
        }
      }
    }),
    label: ({ ownerState }: any) => ({
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      color: getColor(ownerState.color, 20),
      whiteSpace: "normal"
    }),
    deletable: {
      "& .MuiChip-label": {
        paddingRight: "0rem"
      }
    },
    deleteIcon: ({ ownerState }: any) => ({
      color: getColor(ownerState.color, 20),
      paddingTop: "1px",
      margin: 0,
      "&:hover": {
        color: getColor(ownerState.color, 20)
      }
    }),
    sizeMedium: {
      minHeight: "2rem",
      padding: "0.25rem 0.5rem",
      "& .MuiChip-label": {
        fontSize: "1rem",
        fontWeight: "700"
      }
    },
    sizeSmall: {
      minHeight: "1.5rem",
      padding: "0rem 0.25rem",
      "& .MuiChip-label": {
        fontSize: "0.75rem",
        fontWeight: "700"
      }
    }
  }
};

export default MuiChipTheme;
