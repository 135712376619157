import * as React from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import { CaretRight } from "@phosphor-icons/react";

import { adaptProps } from "../mui-stories-utils";
import ThemeLayout from "../Theme/ThemeLayout";
import Typography from "../Typography/Typography";

const ButtonLinkCard = (
  componentProps: ButtonProps & { underline?: boolean }
): React.ReactElement<ButtonProps> => {
  const { children, className, props } = adaptProps(componentProps);
  return (
    <ThemeLayout>
      <Button
        className={className}
        {...props}
        variant="LinkCard"
        fullWidth
        endIcon={
          componentProps.endIcon || <CaretRight weight="bold" size={24} />
        }
      >
        <Typography sx={{ fontWeight: 700, textAlign: "left" }}>
          {children}
        </Typography>
      </Button>
    </ThemeLayout>
  );
};

export default ButtonLinkCard;
