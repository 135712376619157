/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Trans } from "@lingui/react";
import { Collapse, Fade } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip/Chip";
import Paper from "@mui/material/Paper/Paper";
import {
  Warning,
  WarningDiamond,
  XCircle,
  CloudX,
  Plus,
  Minus
} from "@phosphor-icons/react";
import clsx from "clsx";

import Button from "../Button/Button";
import { useIsMobile } from "../hooks";
import colors from "../Theme/colors";
import ThemeLayout from "../Theme/ThemeLayout";
import Typography from "../Typography/Typography";

import { Threshold } from "~/api/companies";

type DateStrObj = { day: string | JSX.Element; time: string };

const AttendancePointsBar = ({
  // Points
  attendancePointsUsed,
  totalAttendancePoints = 0,
  // Dates
  lastUpdatedDate = new Date(),
  // Thresholds
  thresholds,
  lightThreshold = 0,
  severeThreshold = 0,
  terminationThreshold = 0,
  proximityThreshold = 1,
  // Segments
  segmentsNumber,
  showAllSegmentNumbers = true,
  // Info
  infoDetailsText = "",
  infoForbiddenText = "",
  canSeeInfoDetails = false,
  // Date
  unsynced = false,
  showDate = false,
  // Variants
  showVariants = true,
  unavailable = false,
  showAttendancePolicy = true,
  showPointsComponent = true,
  showAttendancePointsCount = false
}: {
  attendancePointsUsed: number;
  totalAttendancePoints?: number;
  lastUpdatedDate?: Date | string;
  thresholds: Threshold[];
  lightThreshold?: number;
  severeThreshold?: number;
  terminationThreshold?: number;
  proximityThreshold?: number;
  segmentsNumber?: number;
  showAllSegmentNumbers?: boolean;
  infoDetailsText?: string;
  infoForbiddenText?: string;
  canSeeInfoDetails?: boolean;
  unsynced?: boolean;
  showDate?: boolean;
  showVariants?: boolean;
  unavailable?: boolean;
  showAttendancePolicy?: boolean;
  showPointsComponent?: boolean;
  showAttendancePointsCount?: boolean;
}): React.ReactElement => {
  const thresholdProps = {
    negative: {
      barColor: "bg-ts-green-50",
      icon: <></>,
      chipText: "",
      policyIcon: "",
      chipColor: "green"
    },
    safe: {
      barColor: "bg-ts-green-50",
      icon: <></>,
      policyIcon: <></>,
      chipText: "",
      chipColor: "green"
    },
    light: {
      barColor: "bg-ts-yellow-50",
      icon: <Warning className="min-w-4 text-ts-yellow-50" />,
      policyIcon: <Warning size={20} className="text-ts-yellow-50" />,
      chipText: "",
      chipColor: "yellow"
    },
    severe: {
      barColor: "bg-ts-red-20",
      icon: <WarningDiamond className="min-w-4 text-ts-red-20" />,
      policyIcon: <WarningDiamond size={20} className="text-ts-red-20" />,
      chipText: "",
      chipColor: "red"
    },
    termination: {
      barColor: "bg-ts-red-20",
      icon: <XCircle weight="fill" className="min-w-4 text-ts-red-20" />,
      policyIcon: (
        <XCircle weight="fill" size={20} className="text-ts-red-20" />
      ),
      chipText: "",
      chipColor: "red"
    },
    overload: {
      barColor: "bg-ts-red-20",
      icon: <></>,
      chipText: "",
      policyIcon: "",
      chipColor: "green"
    }
  };

  const getDateStrObj = (
    date: Date | number | string | undefined
  ): DateStrObj => {
    if (!date) {
      return { day: "", time: "" };
    }
    if (typeof date === "number" || typeof date === "string") {
      date = new Date(date);
    }
    const currentDate = new Date();
    const diff =
      (currentDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);
    let day;
    if (diff < 1 && currentDate.getDate() === date.getDate()) {
      day = <Trans id="attendancePointsBar.header.date.today">Today</Trans>;
    } else if (diff < 2 && currentDate.getDate() - date.getDate() === 1) {
      day = (
        <Trans id="attendancePointsBar.header.date.yesterday">Yesterday</Trans>
      );
    } else {
      const options: any = { day: "numeric", month: "short" };
      day = date.toLocaleDateString("en-US", options);
    }

    const options: any = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZoneName: "short"
    };
    const time = date.toLocaleString("en-US", options);
    return { day, time };
  };

  const getPercentage = (): number => {
    if (attendancePointsUsed === 0 || totalPoints === 0) {
      return 0;
    }
    let percentage = (attendancePointsUsed / totalPoints) * 100;
    percentage = Math.ceil(percentage);
    percentage = percentage > 100 ? 100 : percentage;
    percentage = percentage < 0 ? 0 : percentage;
    return percentage;
  };

  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [usagePercentage, setUsagePercentage] = useState<number>(
    getPercentage()
  );
  const [currentThreshold, setCurrentThreshold] = useState<any>(
    thresholdProps.safe
  );
  const [segments, setSegments] = useState<number[]>([]);
  const [approachingThreshold, setApproachingThreshold] = useState<
    Threshold | undefined
  >();
  const isMobile = useIsMobile();
  const [dateData, setDateData] = useState<DateStrObj>(
    getDateStrObj(lastUpdatedDate)
  );
  const [showExpandButton, setShowExpandButton] = useState(true);
  const collapseHeight = 120;

  useEffect(() => {
    const collapseComponent = document.querySelector(".MuiCollapse-wrapper");
    const onHeightUpdated = (height: number): void => {
      if (height) {
        setShowExpandButton(height > collapseHeight * 1.1);
      }
    };

    if (collapseComponent) {
      onHeightUpdated(collapseComponent.clientHeight);
      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          onHeightUpdated(entry.contentRect.height);
        }
      });

      resizeObserver.observe(collapseComponent);
      return () => {
        resizeObserver.unobserve(collapseComponent);
      };
    }
  }, []);

  const getCurrentThreshold = (): any => {
    switch (true) {
      case attendancePointsUsed < 0:
        return thresholdProps.negative;
      case attendancePointsUsed < lightThreshold:
        return thresholdProps.safe;
      case attendancePointsUsed < severeThreshold:
        return thresholdProps.light;
      case attendancePointsUsed < terminationThreshold:
        return thresholdProps.severe;
      case attendancePointsUsed === terminationThreshold:
        return thresholdProps.termination;
      default:
        return thresholdProps.overload;
    }
  };

  const getSubsegments = (): number[] => {
    if (totalPoints === 0) {
      return [0];
    }
    const roundOneDecimal = (num: number): number => Math.round(num * 10) / 10;
    if (segmentsNumber) {
      const step = totalPoints / segmentsNumber;

      return [
        0,
        ...Array.from({ length: segmentsNumber }, (_, i) =>
          roundOneDecimal(step * (i + 1))
        )
      ];
    }

    const greatestCommonDivisorOfList = (numbers: number[]): number => {
      const gcd = (a: number, b: number): number => {
        while (b !== 0) {
          const temp = b;
          b = a % b;
          a = temp;
        }
        return a;
      };
      if (numbers.length === 0) {
        return Infinity;
      }
      if (numbers.length === 1) {
        return numbers[0];
      }

      let result = numbers[0];
      for (let i = 1; i < numbers.length; i++) {
        result = gcd(result, numbers[i]);
      }
      return result;
    };

    const step = roundOneDecimal(
      greatestCommonDivisorOfList([
        ...thresholds.map(t => t.points),
        totalPoints
      ])
    );
    // the step is the size of the segements that subdivide the bar
    // to calculate the step we find what number divides all the thresholds and the totalPoints
    // so to guarantee that the bar is divided into segments that include all the thresholds
    // EG: if the thresholds are 5, 10, 15, 20, 25, 30 and the totalPoints is 30, the step is 5
    // EG: if the thresholds are 5, 7.5, and the totalPoints is 10, the step is 2.5

    if (step === Infinity || step === 0) {
      return [0];
    }

    const newSegmentsNumber = Math.floor(totalPoints / step);
    const segments = Array.from({ length: newSegmentsNumber }, (_, i) =>
      roundOneDecimal(step * (i + 1))
    );

    return [0, ...segments];
  };

  const calculateMissingValues = (): void => {
    setTotalPoints(
      totalAttendancePoints ||
        thresholds.find(t => t.type === "termination")?.points ||
        0
    );
    if (!lightThreshold) {
      lightThreshold = thresholds.find(t => t.type === "light")?.points || 0;
    }
    if (!severeThreshold) {
      severeThreshold = thresholds.find(t => t.type === "severe")?.points || 0;
    }
    if (!terminationThreshold) {
      terminationThreshold =
        thresholds.find(t => t.type === "termination")?.points || 0;
    }
  };

  useEffect(() => {
    calculateMissingValues();
    setUsagePercentage(getPercentage());
    setCurrentThreshold(getCurrentThreshold());
    const approachingThreshold = thresholds.find(
      (t: Threshold) =>
        attendancePointsUsed >= t.points - proximityThreshold &&
        attendancePointsUsed < t.points
    );
    setApproachingThreshold(approachingThreshold);
  }, [
    attendancePointsUsed,
    totalPoints,
    thresholds,
    lightThreshold,
    severeThreshold,
    terminationThreshold,
    proximityThreshold
  ]);

  useEffect(() => {
    setDateData(getDateStrObj(lastUpdatedDate));
  }, [lastUpdatedDate.toString()]);

  useEffect(() => {
    calculateMissingValues();
  }, []);

  useEffect(() => {
    calculateMissingValues();
    setSegments(getSubsegments());
  }, [segmentsNumber, totalPoints, thresholds]);

  const showSegmentNumber = (segment: number): boolean => {
    if (showAllSegmentNumbers) {
      return true;
    }
    if (segment === 0 || segment === totalPoints) {
      return true;
    }
    return thresholds.some((t: Threshold) => t.points === segment);
  };

  const thresholdSegmentsWidth = (): { middle: string; edges: string } => {
    const middleFactor = 1.25;
    let edgeFactor = 1;
    if (segments.length < 5) {
      edgeFactor = 1.2;
    } else if (segments.length < 10) {
      edgeFactor = 1;
    } else {
      edgeFactor = 0.8;
    }
    return {
      middle: `${Math.round((100 / (segments.length - 2)) * middleFactor)}%`,
      edges: `${Math.round((100 / segments.length) * edgeFactor)}%`
    };
  };

  const ApproachingThresholdTrans = (): React.ReactElement => {
    if (!approachingThreshold?.name) {
      return <></>;
    }
    const thresholdName = approachingThreshold.name;
    return (
      <Trans
        id="attendancePointsBar.header.threshold.approaching"
        values={{ thresholdName }}
      >
        Approaching {thresholdName}
      </Trans>
    );
  };

  const DateTrans = (): React.ReactElement => {
    if (!dateData?.day || !dateData?.time) {
      return <></>;
    }
    const { day, time } = dateData;
    return (
      <Typography
        variant="h5"
        className={clsx(
          "flex !text-left text-ts-gray-40",
          unsynced && "text-ts-red-20"
        )}
      >
        <Trans id="attendancePointsBar.header.date" values={{ day, time }}>
          as of {day} at {time}
        </Trans>
        {unsynced && (
          <>
            {" "}
            (
            <Trans id="attendancePointsBar.header.latestOccurrence">
              May not reflect latest occurrence
            </Trans>
            )
          </>
        )}
      </Typography>
    );
  };

  const HeaderNoChip = (): React.ReactElement => (
    <div className="min-h-12 flex flex-row gap-2.5">
      <Typography
        className="flex self-center"
        variant="h1"
        fontSize="300% !important" // !I-BRB
      >
        {attendancePointsUsed >= 0 ? attendancePointsUsed : 0}
      </Typography>
      <div className={clsx("flex flex-col", !showDate && "relative self-end")}>
        <Typography
          variant={showDate ? "h3" : "h2"}
          className="flex !text-left text-ts-teal-20"
        >
          <Trans
            id="attendancePointsBar.header.pointsUsed"
            values={{ totalPoints }}
          >
            of {totalPoints} Attendance Points used
          </Trans>
        </Typography>
        {showDate && <DateTrans />}
      </div>
    </div>
  );

  const HeaderChip = (): React.ReactElement => (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-2">
        <Typography className="flex self-center !text-5xl" variant="h1">
          {attendancePointsUsed >= 0 ? attendancePointsUsed : 0}
        </Typography>
        {approachingThreshold && (
          <Chip
            className="min-h-6 h-auto whitespace-normal text-left"
            label={<ApproachingThresholdTrans />}
            color={
              thresholdProps[approachingThreshold.type].chipColor as
                | "primary"
                | "warning"
                | "error"
            }
          />
        )}
      </div>
      <div className="flex flex-row gap-2.5">
        <div className="flex flex-col">
          <Typography variant="h3" className="flex !text-left text-ts-teal-20">
            <Trans
              id="attendancePointsBar.header.pointsUsed"
              values={{ totalPoints }}
            >
              of {totalPoints} Attendance Points used
            </Trans>
          </Typography>
          <DateTrans />
        </div>
      </div>
    </div>
  );

  const HeaderChipNoDate = (): React.ReactElement => (
    <div className="flex flex-row gap-2.5">
      <Typography
        className="flex self-center"
        variant="h1"
        fontSize="300% !important" // !I-BRB
      >
        {attendancePointsUsed >= 0 ? attendancePointsUsed : 0}
      </Typography>
      <div className="flex flex-col">
        <Typography variant="h2" className="flex !text-left text-ts-teal-20">
          <Trans
            id="attendancePointsBar.header.pointsUsed"
            values={{ totalPoints }}
          >
            of {totalPoints} Attendance Points used
          </Trans>
        </Typography>
        {approachingThreshold && (
          <Chip
            className="min-h-6 h-auto whitespace-normal text-left"
            label={<ApproachingThresholdTrans />}
            color={
              thresholdProps[approachingThreshold.type].chipColor as
                | "primary"
                | "warning"
                | "error"
            }
          />
        )}
      </div>
    </div>
  );

  const UnavailableHeader = (): React.ReactElement => (
    <div className="min-h-12 flex flex-row gap-2.5">
      <CloudX size={50} className="text-ts-gray-40" />
      <div className="flex flex-col">
        <Typography variant="h3" className="flex !text-left text-ts-gray-40">
          <Trans id="attendancePointsBar.header.pointsUnavailable">
            Point balance unavailable
          </Trans>
        </Typography>
        <DateTrans />
      </div>
    </div>
  );

  const Header = (): React.ReactElement => {
    if (unavailable) {
      return <UnavailableHeader />;
    }
    if (approachingThreshold || !showVariants) {
      if (!showDate) {
        return <HeaderChipNoDate />;
      } else {
        return <HeaderChip />;
      }
    } else {
      return <HeaderNoChip />;
    }
  };

  const Info = ({
    thresholds,
    details,
    initialIsShowingInfo = false
  }: {
    thresholds: Threshold[];
    details: string;
    initialIsShowingInfo?: boolean;
  }): React.ReactElement => {
    const [isShowingInfo, setIsShowingInfo] =
      useState<boolean>(initialIsShowingInfo);
    const filterThresholds = (thresholds: Threshold[]): Threshold[] => {
      const filteredThresholds: Threshold[] = [];
      const thresholdTypes: Threshold["type"][] = [
        "safe",
        "light",
        "severe",
        "termination"
      ];
      thresholdTypes.forEach(type => {
        const thresholdsByType = thresholds.filter(t => t.type === type);
        if (thresholdsByType.length >= 1) {
          filteredThresholds.push(
            thresholdsByType.sort(
              (a, b) =>
                parseFloat(a.notification_threshold) -
                parseFloat(b.notification_threshold)
            )[0]
          );
        }
      });
      return filteredThresholds;
    };
    return (
      <Paper
        className="relative flex w-full flex-col gap-2 !border-none !bg-ts-gray-90"
        sx={{
          padding: "20px !important", // !I-BUA
          paddingTop: "15px !important" // !I-BUA
        }}
      >
        <Collapse
          in={!showExpandButton || isShowingInfo}
          collapsedSize={`${collapseHeight}px`}
        >
          <Box className="relative z-10 flex flex-row items-center justify-between pb-2 pr-1 pt-1">
            <Typography
              variant="h3"
              className="!font-bold leading-5 tracking-wider text-ts-gray-30"
            >
              Attendance Policy
            </Typography>
            {showExpandButton && (
              <Button
                color="secondary"
                sx={{
                  width: "32px",
                  minWidth: "32px",
                  height: "32px",
                  minHeight: "32px",
                  padding: 0
                }}
                onClick={() => setIsShowingInfo(!isShowingInfo)}
              >
                {isShowingInfo ? <Minus size={20} /> : <Plus size={20} />}
              </Button>
            )}
          </Box>
          {thresholds.length > 0 && (
            <div>
              {filterThresholds(thresholds).map((threshold, index) => (
                <div
                  key={index}
                  className="flex flex-row items-center gap-2 py-0.5 md:gap-3"
                >
                  <Typography className="h-5 w-5">
                    {thresholdProps[threshold.type].policyIcon}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="w-11 !leading-none text-ts-gray-40"
                  >
                    {threshold.pointsLimit
                      ? `${threshold.points}-${threshold.pointsLimit}`
                      : `${threshold.points}`}{" "}
                    <Trans id="attendancePointsBar.info.pointsAbbreviation">
                      PTS
                    </Trans>
                  </Typography>
                  <Typography
                    variant="h5"
                    className="flex grow self-center leading-none text-ts-gray-40"
                    sx={{
                      fontWeight: "600 !important"
                    }}
                  >
                    {threshold.name}
                  </Typography>
                </div>
              ))}
            </div>
          )}
          <Typography className="flex flex-col gap-y-0.5 text-left text-ts-teal-20">
            {canSeeInfoDetails
              ? details
                  .split("\n")
                  .map((line, index) => <div key={index}>{line}</div>)
              : infoForbiddenText}
          </Typography>
        </Collapse>
        <Fade in={showExpandButton && !isShowingInfo}>
          <Box className="absolute bottom-4 h-[70px] w-[90%] bg-gradient-to-b from-transparent to-ts-gray-90 to-95%" />
        </Fade>
      </Paper>
    );
  };

  const BarComponent = (): JSX.Element => {
    if (unavailable) {
      return <Header />;
    }
    return (
      <div className="min-w-72 relative flex w-full flex-col gap-3">
        <Header />

        {/* Bar */}
        <div className="relative h-7">
          <div
            className={clsx(
              "absolute z-[1] flex h-7 w-full flex-row rounded py-1",
              "!border !border-ts-gray-60", // !I-BUA
              "divide-x divide-ts-gray-60"
            )}
          >
            {segments.slice(1).map((_, index) => (
              <div key={index} className="flex grow" />
            ))}
          </div>
          <div className="absolute z-[-1] h-7 w-full rounded bg-ts-gray-90" />
          {!unavailable && (
            <div
              className={clsx(
                "absolute h-7 rounded",
                currentThreshold.barColor
              )}
              style={{
                width: `${
                  usagePercentage >= 1 ? usagePercentage : 1 + Number(isMobile)
                }${usagePercentage < 100 ? ".1" : ""}%`
              }}
            />
          )}
        </div>

        {/* Thresholds */}
        <div className="flex flex-row justify-between">
          {segments.map((segment: number, index: number) => (
            <div
              key={index}
              className={clsx(
                "flex flex-col-reverse items-center justify-end gap-x-0.5 sm:flex-row",
                showSegmentNumber(segment) && "min-w-fit",
                index !== 0 &&
                  index !== segments.length - 1 &&
                  `sm:justify-center`,
                index === 0 && `sm:justify-start`,
                index === segments.length - 1 && `sm:justify-end`
              )}
              style={{
                width:
                  index !== 0 && index !== segments.length - 1
                    ? thresholdSegmentsWidth().middle
                    : thresholdSegmentsWidth().edges
              }}
            >
              {showSegmentNumber(segment) && (
                <>
                  {
                    thresholdProps[
                      thresholds.find((t: Threshold) => t.points === segment)
                        ?.type || "safe"
                    ].icon
                  }
                  <Typography className="text-ts-gray-50">{segment}</Typography>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const AttendancePointsCount = (): React.ReactElement => {
    const currentDateStrObj = getDateStrObj(new Date());
    const { day, time } = currentDateStrObj;
    return (
      <Box className="flex flex-col gap-4 text-center">
        <Typography fontWeight={500} className="text-ts-teal-20">
          <Trans id="surveyAbsenceHistory.points.annotation">
            Your points balance is:
          </Trans>
        </Typography>
        <Box className="flex flex-col gap-1">
          <Typography fontSize={32} fontWeight={600}>
            {attendancePointsUsed}
          </Typography>
          <Box className="flex flex-col gap-0">
            <Typography fontWeight={700} className="text-ts-teal-20">
              <Trans id="surveyAbsenceHistory.points.title">
                Attendance Points
              </Trans>
            </Typography>
            <Typography
              fontSize={12}
              fontWeight={500}
              className="text-ts-gray-30"
            >
              <Trans
                id="attendancePointsBar.header.date"
                values={{ day, time }}
              >
                as of {day} at {time}
              </Trans>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const Container = ({
    mainComponent,
    policyComponent
  }: {
    mainComponent: React.ReactElement;
    policyComponent: React.ReactElement;
  }): React.ReactElement => {
    if (showPointsComponent && showAttendancePolicy) {
      return (
        <Box
          className="min-w-[280px] rounded-md"
          sx={{
            border: `1px solid ${colors.gray[70]} !important`, // !I-BUA
            maxWidth: "calc(100vw - 3rem)"
          }}
        >
          <Box
            sx={{
              padding: "20px !important" // !I-BUA
            }}
          >
            {mainComponent}
          </Box>
          <Box className="border-t border-ts-gray-70"></Box>
          <Box>{policyComponent}</Box>
        </Box>
      );
    }
    if (showPointsComponent || showAttendancePolicy) {
      const component = showPointsComponent ? mainComponent : policyComponent;
      return (
        <Box
          className="min-w-[280px] rounded-md"
          sx={{
            border: `1px solid ${colors.gray[70]} !important`, // !I-BUA
            padding: showPointsComponent ? "20px !important" : "", // !I-BUA
            maxWidth: "calc(100vw - 3rem)"
          }}
        >
          {component}
        </Box>
      );
    }
    return <></>;
  };

  return (
    <ThemeLayout>
      <Container
        mainComponent={
          showAttendancePointsCount ? (
            <AttendancePointsCount />
          ) : (
            <BarComponent />
          )
        }
        policyComponent={
          <Info thresholds={thresholds} details={infoDetailsText} />
        }
      />
    </ThemeLayout>
  );
};

export default AttendancePointsBar;
