/**
 * @file surveys.py
 *
 * @description The basic types, and API calls, necessary for the front-end
 * code to implement a survey runner (aka `/s/:token`).
 *
 * Two things *not* covered by the types/API calls found here:
 *
 *  1. All the types necessary to *write* surveys in typescript
 *  2. The API calls necessary to *edit* surveys using our (future) editor tool
 *
 * For those, see `surveyEditor.ts`.
 *
 * FUTURE TODO: separate out "just the types" from the API calls, for even
 * more cleanliness.
 */

import { ISODateTime, LocalizableString, StringTranslations } from "./common";
import {
  Employee,
  EmployeeEvent,
  EmployeeEventSurvey,
  LabelSet,
  ResultsEmployee
} from "./employees";
import {
  APIResponse,
  destroy,
  get,
  PaginatedResponse,
  patch,
  post,
  put,
  Query
} from "./lib";
import { MessageRecipient } from "./messages";

import { FormScheduleRecurrenceDetails } from "~/types";

/** Context for mustache template messages. */
export interface TemplateStringContext {
  employee: Employee;
}

/** Context for rendering mustache templates found in BadgeSteps. */
export interface BadgeContext extends TemplateStringContext {
  eventName: string;
  eventDateRangeShort: string;
  completedDate: string;
  completedTime: string;
}

/**-------------------------------------------------------------
 * Choices
 ---------------------------------------------------------------*/

/** A collection of choices, along with constraints on how many can/must be selected. */
export interface ChoiceSet {
  /** The front-end uses this value to set the fallback value for minChoices to be 1. */
  required: boolean;
  /** The front-end uses this value to set the fallback value for maxChoices to be 1. */
  single: boolean;
  choices: Choice[];
  /** The minimum number of choices required. Defaults to 0 unless required is true. */
  minChoices?: number;
  /** The maximum number of choices allowed. Defaults to Infinity unless single is true. */
  maxChoices?: number;
}

/** A single choice in a "choice"-style question. */
export interface Choice {
  key: string;
  display: string;
  loc_display?: StringTranslations;
}

/**-------------------------------------------------------------
 * Survey Steps
 ---------------------------------------------------------------*/

// A "step" is a single increment of a running survey that requires
// some form of user interaction, or that ends the survey.
// Most steps are questions; see below.

export interface AlertStep {
  kind: "alert";
  title?: LocalizableString;
  body?: LocalizableString;

  /** Presentation layers can decide to use, or ignore, this hint. */
  hintMood: "good" | "bad" | "neutral";
}

export interface BadgeStep {
  kind: "badge";
  header?: LocalizableString;
  title?: LocalizableString;
  body?: LocalizableString;

  /**
   * If true, presentation layers may wish to display employee
   * information alongside the badge.
   */
  hintEmployee?: true;
}

export interface PromptStep {
  prompts: LocalizableString[];
}

/**
 * An arbitrary step that shows a message but requires no answer.
 *
 * This can appear anywhere in a running survey, including as a final step.
 */
export interface MessageStep extends PromptStep {
  kind: "message";

  /** Presentation layers can use this hint to alter their display. */
  hintNext?: LocalizableString;
}

/** Properties common to all number questions. */
export interface NumberQuestionBase extends PromptStep {
  kind: "number";

  /** The minimum allowed value. Default: -inf */
  minValue: number;

  /** The maximum allowed value. Default: +inf */
  maxValue: number;

  /** The increment, if any, that answers should align to. */
  increment: number;

  /** The suggested initial value, if any, that presentation layers can use. */
  hintInitial?: number;
}

/**
 * A number question with no particular dimension.
 *
 * The use of `hint?: never` is a bit of typescript magic: `never` can, well,
 * never happen, so `hint` must always be undefined in this case.
 */
export interface NumberQuestionDimensionless extends NumberQuestionBase {
  hint?: never;
  hintUnit?: never;
}

/** A number question with a temperature dimension. */
export interface NumberQuestionTemperature extends NumberQuestionBase {
  hint: "temperature";
  hintUnit: "F" | "C";
}

/** A number question with a duration dimension. */
export interface NumberQuestionDuration extends NumberQuestionBase {
  hint: "duration";
  hintUnit: "weeks" | "days" | "hours" | "minutes";
}

/** All possible forms of a number question. */
export type NumberQuestion =
  | NumberQuestionDimensionless
  | NumberQuestionTemperature
  | NumberQuestionDuration;

export interface StringQuestion extends PromptStep {
  kind: "string";

  /** The minimum length required. Defaults to 0. */
  minLength?: number;

  /** The maximum length allowed. Defaults to 1024. */
  maxLength?: number;

  /** Example text that presentation layers can use to help prompt users. */
  hintExample?: LocalizableString;
}

export interface ChoiceQuestion extends PromptStep {
  kind: "choice";

  /** The choice set to draw from. */
  choiceSet: ChoiceSet;

  /**
   * Presentation layers can use (or ignore) this hint to adjust their display.
   *
   * The front-end currently does the following:
   * If the hint is "symptoms" and the choice set has required === false, then
   * the Next button changes to read "No Symptoms" when nothing is selected and
   * the Next button turns red when something is selected.
   *
   * The back-end uses a hack to rewrite v1 surveys to include this hint when the
   * node name or message name contained the word "symptoms"
   */

  hint?: "symptoms";
}

// TODO dave & chris -- is this a DATE or a DATE + TIME?
// (we might want both a Date AND a DateTime question?)
export interface DateQuestion extends PromptStep {
  kind: "date";

  /**
   * A hint to the presentation layer about the range of dates to show.
   *
   * This is not actually enforced, since it's relative to "now" and it's
   * not clear what "now" is in all contexts. A problem to solve in the future!
   */
  hintRange:
    | "future"
    | "past"
    | "all"
    | "todayAndFuture"
    | "todayAndPast"
    | "futureAfterEarlierAnswer"
    | "futureFromEarlierAnswer";
  /**
   * A hint to the presentation layer to control how many days into the future the picker should allow.
   * Currently this will default to 365 days when undefined/null and cannot be set further without manually
   * doing so.
   *
   * This is only used for future, all, and todayAndFuture ranges.
   */
  maxFutureDays?: number;
  /**
   * A hint to the presentation layer to control how many days into the past the picker should allow.
   *
   * This is only used for past, all, and todayAndPast ranges.
   */
  maxPastDays?: number;

  hintOtherAnswerName?: string;
}

export type Question =
  | NumberQuestion
  | StringQuestion
  | ChoiceQuestion
  | DateQuestion;

export type InlineStep = MessageStep | Question;

export type FullScreenStep = AlertStep | BadgeStep;

/** The type of all steps in a running survey. */
export type Step = InlineStep | FullScreenStep;

/**-------------------------------------------------------------
 * Survey Answers
 ---------------------------------------------------------------*/

/** The answer to a choice question. */
export interface ChoiceAnswer {
  kind: "choice";
  value: string[];
}

/** The answer to a numeric question. */
export interface NumberAnswer {
  kind: "number";
  value: number;
}

/** The answer to a freeform text question. */
export interface StringAnswer {
  kind: "string";
  value: string;
  pending?: boolean;
}

/** The answer to a date question */
export interface DateAnswer {
  kind: "date";
  value: ISODateTime; // TODO dave + chris (or date only, not datetime?)
}

/** All answer types currently supported. */
export type Answer = ChoiceAnswer | NumberAnswer | StringAnswer | DateAnswer;

export interface SurveyOptions {
  undo?: boolean;
  showLanguages?: boolean;
}

/**-------------------------------------------------------------
 * Survey creation
 ---------------------------------------------------------------*/

export interface SurveyLink {
  /** The URL to visit to "run" the survey (aka /s/<something>) or (/menu/<something>). */
  url: string;

  /** The token to access the survey APIs directly. */
  token: string;
}

export interface SendSurveyInfo {
  template_id: string;
  company_id: string;
  recipients_json: MessageRecipient[];
}

/**-------------------------------------------------------------
 * API calls!
 ---------------------------------------------------------------*/

/**
 * A survey's state describes the current and historical state of a
 * survey instance in a way that is designed to be useful (and not annoying)
 * for presentation layers -- like our website, but also like our REPL.
 */
export interface SurveyState {
  /** The id of the survey instance */
  id: string;

  is_anonymous: boolean;

  /** All previously seen steps, along with their answers (if any). */
  history: {
    /** The name of the step aka the name of the question. */
    name: string;

    /** Details about the step itself. */
    step: Step;

    /**
     * If this is a Question step, the answer that was provided.
     *
     * The type of a historical Answer will *always* match that of the
     * step -- aka if the step is a StringQuestion, the answer will be a
     * StringAnswer.
     */
    answer?: Answer;
  }[];

  /** The current step. There is always one. */
  current: {
    /** The name of the current step. */
    name: string;

    /** Details about the current step. */
    step: Step;
  };

  /** The date + time this survey was first opened */
  started?: ISODateTime;

  /** The date + time this survey was created. (Not the same as "started"!) */
  created: ISODateTime;

  /** The last time this survey was updated. */
  updated: ISODateTime;

  /** Is the current step the last step? */
  on_last_step: boolean;

  /**
   * The date + time this survey was completed, if any. If this is set,
   * the `current` step is also the last, and you should not attempt to
   * advance the survey instance further.
   */
  completed?: ISODateTime;

  /** The options for this survey. */
  options?: SurveyOptions;

  /**
   * The outcome context, if any. This isn't really part of a `survey` itself,
   * but the API adds it here when the survey is completed in order to helpd
   * us continue to behave the way we used to behave with the old API.
   */
  outcome?: {
    /** The collection of employee events created as a result of this survey, if any. */
    employeeEvents: EmployeeEvent[];
  };
}

/** Extended type for API endpoints that explicitly link surveys and employees. */
export interface SurveyStateAndEmployee extends SurveyState {
  employee: Employee;
}

/**
 * Query the server to get the state of a specific survey.
 *
 * On success, returns the current state of the survey instance.
 */
export const getSurveyState = async (
  token: string,
  forceRefresh?: boolean
): Promise<APIResponse<SurveyState>> => {
  return await get(
    `/conversations/survey/${token}/`,
    undefined,
    undefined,
    forceRefresh ? "no-cache" : undefined
  );
};

/**
 * Provide an answer to a single question in a running survey.
 *
 * On success, returns the new and updated state of the survey instance.
 */
export const advanceSurveyState = async (
  token: string,
  /** The name of the step/question we're advancing past. */
  name: string,

  /** If the step is a Question, a valid answer for the question. */
  answer?: Answer
): Promise<APIResponse<SurveyState>> => {
  return await put(`/conversations/survey/${token}/`, { name, answer });
};

/**
 * Undo the previous step in a running survey.
 *
 * On success, returns the new and updated state of the survey instance.
 */
export const undoSurveyState = async (
  token: string
): Promise<APIResponse<SurveyState>> => {
  return await patch(`/conversations/survey/${token}/`, {});
};

/**
 * Start a survey instance over. This cannot be used if the survey is completed.
 *
 * On success, returns the updated state of the survey instance.
 */
export const resetSurveyState = async (
  token: string
): Promise<APIResponse<SurveyState>> => {
  return await destroy(`/conversations/survey/${token}/`);
};

/**
 * Query the server to obtain a specific employee's survey history.
 */
export const retrieveEmployeeSurveyHistory = async (
  company_id: string,
  employee_id: string,
  options?: {
    surveyTemplateId?: string;
    limit?: number;
  }
): Promise<APIResponse<PaginatedResponse<SurveyState>>> => {
  return await get(
    `/conversations/company/${company_id}/employee/${employee_id}/`,
    {
      ...(options?.limit ? { limit: `${options?.limit}` } : {}),
      ...(options?.surveyTemplateId
        ? { survey_template_id: `${options?.surveyTemplateId}` }
        : {})
    }
  );
};

/**
 * Query the server to obtain the survey state associated with
 * an employee event (if it was created via a survey).
 */
export const retrieveEmployeeEventSurvey = (
  company_id: string,
  employee_event_id: string
): Promise<APIResponse<SurveyState>> =>
  get(
    `/conversations/company/${company_id}/employee_event/${employee_event_id}/`
  );

/**
 * Query the server to obtain the last 24 hours of call-off surveys.
 */
export const retrieveTodaysAttendanceSurveys = async (
  company_id: string,
  options?: {
    limit?: number;
  }
): Promise<APIResponse<PaginatedResponse<SurveyStateAndEmployee>>> => {
  return await get(`/conversations/company/${company_id}/today/`, {
    survey_kind: "call-off",
    ...(options?.limit ? { limit: `${options?.limit}` } : {})
  });
};

/**
 * Query the server to obtain surveys for all employees.
 *
 * backend: VisitorSurveysAPIView
 */
export const retrieveVisitorSurveyHistory = async (
  company_id: string,
  options?: {
    limit?: number;
  }
): Promise<APIResponse<PaginatedResponse<SurveyStateAndEmployee>>> => {
  return await get(`/conversations/company/${company_id}/visitor/`, {
    ...(options?.limit ? { limit: `${options?.limit}` } : {})
  });
};

/**
 * Have the server get the current survey for an employee, or create a new one
 * if the employee has no current survey.
 *
 * backend: ScreenerGetOrCreateConversation
 */
export const getOrCreateScreenerSurvey = async (
  company_id: string,
  employee_id: string,
  token: string
): Promise<APIResponse<SurveyLink>> => {
  const headers = { "X-EmployeeTokenSession": token };
  return await post(
    `/conversations/company/${company_id}/screener/employee/${employee_id}/`,
    {},
    headers
  );
};

/**
 * Request that a survey is created and sent to your email or phone.
 */
export const requestConversationForGo = async (
  phone_number?: string,
  notify_email?: string
): Promise<APIResponse<object>> => {
  return await post(`/conversations/request_conversation/`, {
    phone_number,
    notify_email
  });
};

/**
 * Request a new conversation for a visitor in "go" mode.
 */
export const createVisitorConversationForGo = async (
  visitor_code: string,
  phone_number: string,
  name: string
): Promise<APIResponse<SurveyLink>> => {
  return await post(`/conversations/visitor_code/${visitor_code}/`, {
    phone_number,
    name
  });
};

/**
 * Request a survey (or survey menu) URL for an employee given a requestSurveyToken
 */
export const requestSurveyFromSMSIdentification = async (
  requestSurveyToken: string
): Promise<APIResponse<SurveyLink>> => {
  const headers = { "X-EmployeeTokenSession": requestSurveyToken };
  return await post(`/conversations/request_survey/`, {}, headers);
};

/**
 * Create a new survey for an employee given a requestSurveyToken
 * and surveyTemplateId
 */
export const createSurveyFromMenu = async (
  requestSurveyToken: string,
  surveyTemplateId: string
): Promise<APIResponse<SurveyLink>> => {
  const headers = { "X-EmployeeTokenSession": requestSurveyToken };
  return await post(
    `/conversations/create_survey/${surveyTemplateId}/`,
    {},
    headers
  );
};

/**
 * The identifier and name of a back-end ConversationTemplate record.
 *
 * The word "template" here is pretty confusing, since we also use "template"
 * to mean "Mustache templating system template". Here, it means "template"
 * in the sense of "conversation template", which is our oldest naming for this
 * stuff.
 */
export interface SurveyTemplate {
  id: string;
  name: string;
}

/** Retrieve available "non-anonymous" survey templates for a given employee (admin dropdown) */
export const retrieveSurveyTemplates = (
  company_id: string,
  employee_id: string
): Promise<APIResponse<SurveyTemplate[]>> =>
  get(
    `/conversations/company/${company_id}/employee/${employee_id}/survey_templates/`
  );

export interface SurveySummary {
  id: string;
  name: string;
  responses: number;
  today: number;
  is_anonymous: boolean;
  is_covid: boolean;
  behaviors?: Array<string>;
  created: ISODateTime;
  latest?: ISODateTime;
}

export interface SurveysCompleted {
  totalSurveys: number;
  totalSurveysCompleted: number;
}

export interface SurveyCompletionMedianTime {
  medianTime?: number;
}

export interface SurveysSummaryResponse {
  data: SurveySummary[];
}

/** Retrieve available survey templates for a given employee (admin dropdown) */
export const retrieveSurveysSummary = (
  company_id: string
): Promise<APIResponse<SurveysSummaryResponse>> =>
  get(`/conversations/company/${company_id}/surveys_summary/`);

export interface SurveyMenuResponse {
  data: SurveyTemplate[];
}

/** Retrieve available survey templates for a given employee (survey menu) */
export const retrieveSurveyTemplatesMenu = (
  company_id: string,
  employee_id: string,
  options?: {
    token?: string;
  }
): Promise<APIResponse<SurveyMenuResponse>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return get(
    `/conversations/company/${company_id}/employee/${employee_id}/templates_menu/`,
    undefined,
    headers
  );
};

export const sendSurvey = async (
  surveyInfo: SendSurveyInfo
): Promise<APIResponse<{}>> => {
  return await post(
    `/conversations/company/${surveyInfo.company_id}/send_survey/`,
    surveyInfo
  );
};

/***
 *  SURVEY REMINDERS
 *  ---------------- ***/
export interface SurveyReminder {
  id?: string;
  company_id?: string;
  survey_template: string;
  duration: number;
}

/** Retrieve available survey reminders for a specific survey/conversation */
export const retrieveSurveyReminders = (
  company_id: string,
  template_id: string
): Promise<APIResponse<SurveyReminder[]>> =>
  get(
    `/conversations/company/${company_id}/template/${template_id}/reminders/`
  );

export const addSurveyReminder = (
  reminder: SurveyReminder
): Promise<APIResponse<SurveyReminder>> =>
  post(
    `/conversations/company/${reminder.company_id}/template/${reminder.survey_template}/reminders/`,
    reminder
  );

export const updateSurveyReminder = (
  reminder: SurveyReminder
): Promise<APIResponse<SurveyReminder>> =>
  put(
    `/conversations/company/${reminder.company_id}/template/${reminder.survey_template}/reminder/${reminder.id}/`,
    reminder
  );

export const deleteSurveyReminder = (
  reminder: SurveyReminder
): Promise<APIResponse<object>> =>
  destroy(
    `/conversations/company/${reminder.company_id}/template/${reminder.survey_template}/reminder/${reminder.id}/`
  );

export const retrieveSurveySummary = (
  company_id: string,
  template_id: string,
  options?: {
    token?: string;
  }
): Promise<APIResponse<SurveySummary>> => {
  let headers;
  if (options?.token) {
    headers = { "X-EmployeeTokenSession": options.token };
  }
  return get(
    `/conversations/company/${company_id}/survey_summary/${template_id}/`,
    undefined,
    headers
  );
};

export const retrieveSurveySchedules = (
  company_id: string,
  template_id: string
): Promise<APIResponse<FormScheduleRecurrenceDetails[]>> =>
  get(
    `/conversations/company/${company_id}/template/${template_id}/schedules/`
  );

export const addSurveySchedule = (
  schedule: FormScheduleRecurrenceDetails
): Promise<APIResponse<FormScheduleRecurrenceDetails>> =>
  post(
    `/conversations/company/${schedule.companyId}/template/${schedule.templateId}/schedules/`,
    schedule
  );

export const updateSurveySchedule = (
  schedule: FormScheduleRecurrenceDetails
): Promise<APIResponse<FormScheduleRecurrenceDetails>> =>
  put(
    `/conversations/company/${schedule.companyId}/template/${schedule.templateId}/schedules/${schedule.id}/`,
    schedule
  );

export const deleteSurveySchedule = (
  schedule: FormScheduleRecurrenceDetails
): Promise<APIResponse<object>> =>
  destroy(
    `/conversations/company/${schedule.companyId}/template/${schedule.templateId}/schedules/${schedule.id}/`
  );

export interface SurveyFilterOptions {
  teamLeadIds?: string[];
  groupIds?: string[];
  divisionIds?: string[];
  startTime?: string;
  endTime?: string;
}

const queryFromFilterOptions: (
  filterOptions?: SurveyFilterOptions
) => Query = options => ({
  ...(options?.teamLeadIds
    ? { team_lead_id__in: options.teamLeadIds.join(",") }
    : {}),
  ...(options?.groupIds ? { group_id__in: options.groupIds.join(",") } : {}),
  ...(options?.divisionIds
    ? { division_id__in: options.divisionIds.join(",") }
    : {}),
  ...(options?.startTime ? { start_time: options.startTime } : {}),
  ...(options?.endTime ? { end_time: options.endTime } : {})
});

export const retrieveSurveysCompletedCount = (
  company_id: string,
  template_id: string,
  filterOptions?: SurveyFilterOptions
): Promise<APIResponse<SurveysCompleted>> =>
  get(
    `/conversations/company/${company_id}/template/${template_id}/results/count/`,
    queryFromFilterOptions(filterOptions)
  );

export const retrieveSurveyCompletionMedianTime = (
  company_id: string,
  template_id: string,
  filterOptions?: SurveyFilterOptions
): Promise<APIResponse<SurveyCompletionMedianTime>> =>
  get(
    `/conversations/company/${company_id}/template/${template_id}/results/completion_time/`,
    queryFromFilterOptions(filterOptions)
  );

export interface SurveyQuestion {
  name: string;
  question: string;
}

export interface SurveyTextResultsAnswers {
  name: string;
  value: string;
  kind: string;
  employee: ResultsEmployee;
}

export interface SurveyTextResults extends SurveyQuestion {
  answers?: SurveyTextResultsAnswers[];
  isAnonymous: boolean;
}

export interface AttendanceEvent {
  label: string;
  displayText: string;
  color: string;
  count: number;
}

export interface AttendancePoints {
  updated: string;
  count: number;
}

export interface AttendanceEventHistory {
  color: string;
  isOpen?: boolean;
  event: EmployeeEvent;
  survey?: SurveyState;
}

export interface AttendanceEventHistoryByDate {
  isOpen?: boolean;
  date: string;
  events: {
    survey?: SurveyState;
    color: string;
    event: EmployeeEvent;
  }[];
}

export interface AttendanceEventHistorySurvey {
  color: string;
  isOpen?: boolean;
  event: EmployeeEventSurvey;
}

// Retrieve text-based results of a survey
export const retrieveSurveyTextResults = (
  company_id: string,
  template_id: string,
  filterOptions?: SurveyFilterOptions
): Promise<APIResponse<SurveyTextResults[]>> =>
  get(
    `/conversations/company/${company_id}/template/${template_id}/results/input/`,
    queryFromFilterOptions(filterOptions)
  );

export interface SurveyChoiceAnswers {
  name: string;
  display: string;
  count: number;
}

export interface SurveyChoiceResults extends SurveyQuestion {
  totalCount: number;
  answers: SurveyChoiceAnswers[];
}

export const retrieveSurveyChoiceResults = (
  company_id: string,
  template_id: string,
  filterOptions?: SurveyFilterOptions
): Promise<APIResponse<SurveyChoiceResults[]>> =>
  get(
    `/conversations/company/${company_id}/template/${template_id}/results/choice/`,
    queryFromFilterOptions(filterOptions)
  );

export const retrieveSurveySkipResults = (
  company_id: string,
  template_id: string,
  filterOptions?: SurveyFilterOptions
): Promise<APIResponse<SurveyQuestion[]>> =>
  get(
    `/conversations/company/${company_id}/template/${template_id}/results/skip/`,
    queryFromFilterOptions(filterOptions)
  );

export const retrieveShowAttendanceHistory = async (
  company_id: string | undefined,
  employee_id: string | undefined,
  token: string | undefined
): Promise<APIResponse<Boolean> | undefined> => {
  if (company_id && employee_id && token) {
    const response: APIResponse<any> = await get(
      `/companies/${company_id}/employees/${employee_id}/show_attendance_history/?t=${token}`
    );

    if (response && "data" in response && "data" in response.data) {
      response.data = response.data.data;
    }
    return response;
  }
};

export const getEmployeeAttendancePoints = async (
  company_id: string,
  employee_id: string
): Promise<
  APIResponse<{
    attendancePoints?: AttendancePoints;
  }>
> => {
  const response: APIResponse<any> = await get(
    `/companies/${company_id}/employees/${employee_id}/attendance_points/`
  );

  if ("data" in response && "data" in response.data) {
    const convertedResponse: APIResponse<{
      attendancePoints?: AttendancePoints;
    }> = {
      ok: true,
      status: 200,
      data: {
        attendancePoints: response.data.data
      }
    };

    return convertedResponse;
  }

  return {
    ok: false,
    status: 500,
    errors: {
      non_field_errors: ["Data not found"]
    }
  };
};

export const getEmployeeAttendanceEventsHistory = async (
  company_id: string,
  employee_id: string
): Promise<APIResponse<EmployeeEvent[]>> => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  const response: APIResponse<EmployeeEvent[]> = await get(
    `/companies/${company_id}/employees/${employee_id}/events/`,
    {
      started_from: date.toISOString(),
      ordering: "-started,-created",
      only_labelset: "attendance_status"
    }
  );

  return response;
};

export const getEmployeeAttendanceEventsHistorySurvey = async (
  company_id: string,
  employee_id: string,
  labelSets?: {
    exclude?: LabelSet[];
    only?: LabelSet[];
  }
): Promise<APIResponse<EmployeeEventSurvey[]>> => {
  const baseURL = `/companies/${company_id}/employees/${employee_id}/events_survey/`;
  const searchParams = new URLSearchParams({});
  labelSets?.exclude?.forEach(labelSet =>
    searchParams.append("excluded_label_sets", labelSet)
  );
  labelSets?.only?.forEach(labelSet =>
    searchParams.append("only_included_label_sets", labelSet)
  );

  const response: APIResponse<EmployeeEventSurvey[]> = await get(
    `${baseURL}?${searchParams.toString()}`
  );

  return response;
};

export const getEmployeeAttendanceEvents = async (
  company_id: string,
  employee_id: string
): Promise<
  APIResponse<{
    eventCounts: AttendanceEvent[];
  }>
> => {
  const response: APIResponse<any> = await get(
    `/companies/${company_id}/employees/${employee_id}/attendance_events/`
  );

  if ("data" in response && "data" in response.data) {
    const convertedResponse: APIResponse<{
      eventCounts: AttendanceEvent[];
    }> = {
      ok: true,
      status: 200,
      data: {
        eventCounts: response.data.data.map(({ label, color, count }: any) => ({
          label,
          color,
          count
        }))
      }
    };

    return convertedResponse;
  }

  return {
    ok: false,
    status: 500,
    errors: {
      non_field_errors: ["Data not found"]
    }
  };
};

export const getEmployeeAttendanceHistory = async (
  company_id: string,
  employee_id: string
): Promise<
  APIResponse<{
    eventCounts: AttendanceEvent[];
    attendancePoints?: AttendancePoints;
  }>
> => {
  const response: APIResponse<any> = await get(
    `/companies/${company_id}/employees/${employee_id}/attendance_history/`
  );

  if (
    "data" in response &&
    "data" in response.data &&
    "eventCounts" in response.data.data
  ) {
    const convertedResponse: APIResponse<{
      eventCounts: AttendanceEvent[];
      attendancePoints?: AttendancePoints;
    }> = {
      ok: true,
      status: 200,
      data: {
        eventCounts: response.data.data.eventCounts.map(
          ({ label, color, count }: any) => ({
            label,
            color,
            count
          })
        ),
        attendancePoints: response.data.data.attendancePoints
      }
    };

    return convertedResponse;
  }

  return {
    ok: false,
    status: 500,
    errors: {
      non_field_errors: ["Data not found"]
    }
  };
};

export const getEventsPointsSynced = async (
  company_id: string,
  employee_id: string
): Promise<APIResponse<{ synced: boolean }>> => {
  const response: APIResponse<any> = await get(
    `/companies/${company_id}/employees/${employee_id}/events_points_synced/`
  );
  if (response && "data" in response && "data" in response.data) {
    response.data = response.data.data;
  }
  return response;
};
