import React, { FunctionComponent } from "react";

import clsx from "clsx";

import * as api from "~/api";
import ButtonLinkCard from "~/mui-components/LinkCard/LinkCard";

interface SurveyListProps {
  className?: string;
  templates?: api.SurveyTemplate[];
  onClick: (template: api.SurveyTemplate) => any;
}

export const SurveyList: FunctionComponent<SurveyListProps> = ({
  className,
  templates,
  onClick
}) => {
  return (
    <div
      className={clsx(
        "w-full flex flex-grow overflow-y-hidden flex-col",
        className
      )}
      dir="auto"
    >
      <div className="pt-4 px-4 flex flex-grow overflow-y-auto">
        <div className="space-y-4 w-full">
          {(templates ?? []).map(survey => (
            <ButtonLinkCard
              key={survey.id}
              size="large"
              onClick={() => onClick(survey)}
            >
              {survey.name}
            </ButtonLinkCard>
          ))}
        </div>
      </div>
    </div>
  );
};
